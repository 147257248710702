import { APIError, CustomError, UnknownError } from "./error";
import { textUtils } from "@utils/text";

/* Types */
import { ErrorType, ErrorResource } from "./types";

export const HandleError = (e: ErrorType): CustomError => {
  const { axios, resource } = e;

  // If error is of type axios
  if (axios) return handleAxiosError(axios, resource);

  return handlePageError(e.page as Error);
};

const handleAxiosError = (e: ErrorType["axios"], resource: ErrorResource) => {
  let msg = "";
  switch (e?.status) {
    case 404:
      msg = `${textUtils.capitalize(resource)} not found`;
      break;
    default:
      msg =
        "There was something wrong with the server. Our team is working on it.";
  }

  return new APIError(e?.status || 500, msg, e?.statusText);
};

const handlePageError = (e: Error) => {
  return new UnknownError(
    "We weren't able to load the page. Our team is working on it.",
    e.message,
  );
};
