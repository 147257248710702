import { AxiosError } from "axios";

export type ErrorJSON = {
  message: string;
  errorCode: ErrorCode;
  cause: string;
};

export type ErrorType = {
  axios?: AxiosError["response"];
  resource: ErrorResource;
  page?: Error;
};

export enum ErrorCode {
  API_ERROR = 1000,
  UNKNOWN_ERROR = 500,
}

export type ErrorResource = "course" | "project" | "unknown";
