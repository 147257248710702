"use client";
import { useMemo } from "react";

/* Components */
import { ErrorLayout } from "@layouts/error/error";

/* Utils */
import { HandleError } from "@utils/error";

type Props = {
  children?: React.ReactNode;
  reset: () => void;
  error: Error;
};

const ErrorBoundary: React.FC<Props> = (props) => {
  const { error, reset } = props;

  const err = useMemo(
    () => HandleError({ page: error, resource: "unknown" }),
    [error],
  );

  return <ErrorLayout error={err.getJSON()} reset={reset} />;
};

export default ErrorBoundary;
