import { ErrorCode, ErrorJSON } from "./types";
import { HttpStatusCode } from "axios";

export abstract class CustomError extends Error {
  errorCode: ErrorCode;
  constructor(message: string, errorCode: ErrorCode, cause?: string) {
    super(message, { cause });
    this.errorCode = errorCode;
  }

  getJSON(): ErrorJSON {
    return {
      message: this.message,
      errorCode: this.errorCode,
      cause: this.cause as string,
    };
  }
}

export class APIError extends CustomError {
  code: HttpStatusCode;
  constructor(code: number, message: string, cause?: string) {
    super(message, ErrorCode.API_ERROR, cause);
    this.code = code;
  }
}

export class UnknownError extends CustomError {
  constructor(message: string, cause?: string) {
    super(message, ErrorCode.UNKNOWN_ERROR, cause);
  }
}
